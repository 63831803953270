import React from 'react'
import style from './../styles/invest.module.css';

export default function Thanks() {
  return (
    <div>
        <section className={style.bg}>
          Message Sent Successfully, Thanks.
        </section>
    </div>
  )
}
